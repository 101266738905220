<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current text-${color}`" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_174_5512)">
      <path
        d="M34.5001 16.4985H31.5V13.4985C31.5 12.6701 30.8285 11.9985 30.0001 11.9985C29.1716 11.9985 28.5001 12.6701 28.5001 13.4985V16.4985H25.5001C24.6716 16.4985 24.0001 17.1701 24.0001 17.9985C24.0001 18.8269 24.6716 19.4985 25.5001 19.4985H28.5001V22.4985C28.5001 23.3269 29.1716 23.9985 30.0001 23.9985C30.8285 23.9985 31.5 23.3269 31.5 22.4985V19.4985H34.5001C35.3285 19.4985 36 18.8269 36 17.9985C36 17.1701 35.3285 16.4985 34.5001 16.4985Z"
      />
      <path d="M13.5 18C18.4706 18 22.5 13.9706 22.5 9C22.5 4.02944 18.4706 0 13.5 0C8.52944 0 4.5 4.02944 4.5 9C4.5 13.9706 8.52944 18 13.5 18Z" fill="#299FD9" />
      <path
        d="M13.5 20.9985C6.04758 21.0068 0.00829688 27.0461 0 34.4985C0 35.327 0.671555 35.9985 1.49998 35.9985H25.5C26.3284 35.9985 26.9999 35.327 26.9999 34.4985C26.9917 27.0461 20.9524 21.0068 13.5 20.9985Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_174_5512">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '36'
    },
    height: {
      type: String,
      default: '36'
    }
  }
}
</script>
