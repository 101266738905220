<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current text-${color}`" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.739 5.94207C20.5067 5.24528 20.061 4.63924 19.4652 4.20976C18.8693 3.78028 18.1535 3.54914 17.419 3.54907H16.36L15.816 1.91707C15.6833 1.51866 15.4286 1.17213 15.0879 0.926589C14.7472 0.681052 14.3379 0.548973 13.918 0.549074H10.802C10.3817 0.548552 9.97196 0.680437 9.63089 0.926004C9.28983 1.17157 9.03479 1.51834 8.90199 1.91707L8.35999 3.54907H7.30099C6.56637 3.54921 5.85041 3.7805 5.25455 4.21016C4.65869 4.63983 4.21313 5.2461 3.98099 5.94307L3.11199 8.54908H0.359985V11.5491H2.11199L1.45999 13.5181C1.11379 13.8427 0.837619 14.2347 0.648454 14.6699C0.459289 15.1052 0.361122 15.5745 0.359985 16.0491L0.359985 21.5491H3.35999V24.5491H7.35999V21.5491H17.36V24.5491H21.36V21.5491H24.36V16.0491C24.3588 15.5745 24.2607 15.1052 24.0715 14.6699C23.8824 14.2347 23.6062 13.8427 23.26 13.5181L22.608 11.5491H24.36V8.54908H21.608L20.739 5.94207ZM17.36 17.0491C17.36 16.6513 17.518 16.2697 17.7993 15.9884C18.0806 15.7071 18.4622 15.5491 18.86 15.5491C19.2578 15.5491 19.6393 15.7071 19.9206 15.9884C20.202 16.2697 20.36 16.6513 20.36 17.0491C20.36 17.4469 20.202 17.8284 19.9206 18.1097C19.6393 18.391 19.2578 18.5491 18.86 18.5491C18.4622 18.5491 18.0806 18.391 17.7993 18.1097C17.518 17.8284 17.36 17.4469 17.36 17.0491ZM7.30099 6.54907H17.419C17.5239 6.54915 17.6261 6.58222 17.7112 6.6436C17.7962 6.70498 17.8598 6.79155 17.893 6.89107L19.779 12.5491H4.94099L6.82699 6.89107C6.86013 6.79155 6.92374 6.70498 7.00881 6.6436C7.09387 6.58222 7.19609 6.54915 7.30099 6.54907ZM5.85999 18.5491C5.46216 18.5491 5.08063 18.391 4.79932 18.1097C4.51802 17.8284 4.35999 17.4469 4.35999 17.0491C4.35999 16.6513 4.51802 16.2697 4.79932 15.9884C5.08063 15.7071 5.46216 15.5491 5.85999 15.5491C6.25781 15.5491 6.63934 15.7071 6.92065 15.9884C7.20195 16.2697 7.35999 16.6513 7.35999 17.0491C7.35999 17.4469 7.20195 17.8284 6.92065 18.1097C6.63934 18.391 6.25781 18.5491 5.85999 18.5491Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '25'
    },
    height: {
      type: String,
      default: '25'
    }
  }
}
</script>
