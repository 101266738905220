<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current text-${color}`" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.5 12.0002H32.31C31.7863 10.6125 30.9727 9.35247 29.9235 8.30418L24.6976 3.07519C23.648 2.02718 22.3876 1.21415 21 0.690186V10.5002C21 11.3285 21.6716 12.0002 22.5 12.0002Z" />
    <path
      d="M32.964 15H22.5C20.0147 15 18 12.9852 18 10.5V0.036C17.7585 0.0194766 17.517 0 17.2725 0H10.5C6.35991 0.00499219 3.00495 3.35995 3.00003 7.50002V28.5C3.00495 32.64 6.35991 35.9951 10.5 36H25.5C29.64 35.9951 32.995 32.64 33 28.5V15.7275C33 15.483 32.9805 15.2415 32.964 15Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '36'
    },
    height: {
      type: String,
      default: '36'
    }
  }
}
</script>
