<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current text-${color}`" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_174_5550)">
      <path
        d="M19.254 0.0417053C16.6823 -0.138233 14.102 0.236324 11.6877 1.14003C9.27331 2.04374 7.08124 3.45551 5.25977 5.27982C3.43831 7.10413 2.02996 9.2984 1.13002 11.7142C0.230083 14.1299 -0.140447 16.7108 0.0435046 19.2822C0.705005 28.8117 9.12301 35.9997 19.6245 35.9997H28.5C30.4884 35.9973 32.3947 35.2064 33.8007 33.8004C35.2067 32.3944 35.9976 30.4881 36 28.4997V18.5097C36.056 13.8859 34.3607 9.41205 31.2545 5.98646C28.1483 2.56088 23.8612 0.437161 19.254 0.0417053ZM28.05 14.5677L17.8605 24.6282C17.2982 25.1883 16.5369 25.5028 15.7433 25.5028C14.9496 25.5028 14.1883 25.1883 13.626 24.6282L7.95001 19.0722C7.66556 18.7937 7.50339 18.4137 7.49917 18.0156C7.49495 17.6176 7.64903 17.2342 7.9275 16.9497C8.20598 16.6653 8.58605 16.5031 8.98409 16.4989C9.38214 16.4947 9.76556 16.6487 10.05 16.9272L15.7395 22.4877L25.9485 12.4377C26.2364 12.1844 26.6104 12.051 26.9936 12.0648C27.3768 12.0787 27.7402 12.2388 28.009 12.5122C28.2779 12.7857 28.4318 13.1517 28.4391 13.5351C28.4464 13.9185 28.3067 14.2902 28.0485 14.5737L28.05 14.5677Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_174_5550">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '36'
    },
    height: {
      type: String,
      default: '36'
    }
  }
}
</script>
