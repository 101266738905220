<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current text-${color}`" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_174_5538)">
      <path
        d="M33.3 3.24449C32.4449 2.53103 31.4416 2.01696 30.3631 1.7396C29.2845 1.46224 28.1577 1.42855 27.0645 1.64099L24.4185 2.28899C23.0376 2.54203 21.7892 3.27125 20.8906 4.34972C19.9919 5.4282 19.4998 6.78766 19.5 8.19149V31.392C18.5053 31.5375 17.4947 31.5375 16.5 31.392V8.19149C16.505 6.8006 16.0243 5.4516 15.1408 4.3773C14.2574 3.30299 13.0266 2.57077 11.661 2.30699L8.84999 1.62299C7.76872 1.42513 6.65718 1.46736 5.59405 1.7467C4.53092 2.02604 3.54218 2.53565 2.69783 3.23947C1.85348 3.94328 1.17416 4.82409 0.707958 5.81955C0.241752 6.81501 5.79599e-05 7.90077 -1.52588e-05 8.99999L-1.52588e-05 25.1895C7.0925e-05 26.9457 0.61645 28.6462 1.74168 29.9945C2.86692 31.3429 4.42965 32.2536 6.15749 32.568L15.5865 34.2825C17.1824 34.5725 18.8176 34.5725 20.4135 34.2825L29.85 32.568C31.5764 32.2521 33.1374 31.3407 34.2612 29.9925C35.3849 28.6442 36.0002 26.9446 36 25.1895V8.99999C36.0007 7.90116 35.7591 6.81569 35.2924 5.82088C34.8257 4.82607 34.1454 3.94639 33.3 3.24449Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_174_5538">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '36'
    },
    height: {
      type: String,
      default: '36'
    }
  }
}
</script>
